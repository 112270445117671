<template>
  <div class="CitasConfirmacion">
    <v-row class="mt24">
      <v-col
        cols="12"
        sm="6"
        md="4"
        offset-sm="3"
        offset-md="4"
      >
        <v-card
          flat
          :disabled="cargando"
          :loading="cargando"
        >
          <v-card-title>¡Se ha programado tu cita!</v-card-title>
          <v-row>
            <v-col
              cols="8"
              offset="2"
              sm="12"
              offset-sm="0"
            >
              <!-- <v-img src="@/assets/avatars/citas/cita-guardada.png"></v-img> -->
              <v-img src="@/assets/logo.png"></v-img>
            </v-col>
          </v-row>
          <div style="margin:16px">
            <div class="title mt24">
              Nos vemos el {{$Datetime.fromSQL(cita.fecha).setLocale('es').toLocaleString($Datetime.DATE_HUGE)}} a las {{$Datetime.fromSQL(cita.fecha).setLocale('es').toFormat('h:mm a')}}.
            </div>
            <p>Te recomendamos llegar por lo menos 10 minutos antes de tu cita</p>
          </div>
          <v-card-actions>
            <v-btn
              block
              depressed
              class="primary"
              to="/"
            >Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "CitasConfirmacion",
  mixins: [standard, forms],
  data() {
    return {
      cita: {},
    };
  },
  methods: {
    _getCita() {
      this._getThings("citas/" + this.$route.params.idCit, "cita");
    },
  },
  created() {
    //
    if (this.$route.params.idCit) {
      this._getCita();
    } else {
      this.$emit("msg", "Ups, ha sucedido un error");
      this.$router.push("/");
    }
  },
};
</script>